import React from "react";
import "@scss/pages/news/index.scss"

import preview_1 from '../../images/pages/news/preview-1.png';
import preview_2 from '../../images/pages/news/preview-2.png';
import {Link, router} from "@inertiajs/react";

export default function NewsBlock({id, category, date, title, text}) {
    return (
        <div className="news-block">
            {
                id === 1 ?
                    <Link href="/news/pharma-ai-trends-2025" className="news-block__link">&nbsp;</Link> :
                    <Link href="/news/innovative-commercial-tools" className="news-block__link">&nbsp;</Link>
            }
            <div className="news-block__wrapper">
                <div className="news-block__preview">
                    {
                        id === 1 ?
                            <img src={preview_1} alt="Preview news card" />
                            :
                            <img src={preview_2} alt="Preview news card"/>
                    }
                </div>
                <div className="news-block__main-info">
                    <div className="news-block__service-info">
                        <div className="news-block__category">
                            <span>{category}</span>
                        </div>
                        <span className="news-block__date">{date}</span>
                    </div>
                    <h3 className="news-block__title mt-[11px]" dangerouslySetInnerHTML={{ __html: title }}></h3>
                    <p className="news-block__text mt-25px" dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
            </div>
        </div>
    );
}
